import React, {useState} from "react";
import "./Header.css";

const Header = () => {

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [megaDropdownOpen, setMegaDropdownOpen] = useState(false);
  const [academicDropdownOpen, setAcademicDropdownOpen] = useState(false);

  return (
    <div>
      <section className="nav-Topbar">
      <div className="navbar navbar-expand-lg">
        <div className="container mainNavCont">
          
          <div className="mainLogo">
            <div>
              <a className="navbar-brand" href="index.html">
                <img 
                  src="https://www.sankaranethralaya.org/assets/img/sn-mrf-logo.png" 
                  className="mrfLogo" 
                  alt="Sankara Nethralaya Logo"
                />
              </a>
            </div>
            <div className="snName">
              <h3 className="logoName">Sankara Nethralaya</h3>
              <p className="logosubname">Temple of the EYE</p>
              <p className="logomrf">(A Unit of Medical Research Foundation)</p>
            </div>
          </div>

          
          <div className="nfbContainer">
            <a className="nabh-img" href="https://www.sankaranethralaya.org/sn-demo">
              <img 
                src="https://www.sankaranethralaya.org/assets/img/nabh-logo.png" 
                className="img-fluid nabhImg" 
                alt="NABH Logo"
              />
            </a>
            <a 
              className="btn btn-primary btn-lg findCont" 
              href="https://www.sankaranethralaya.org/findadoctor"
            >
              <i className="material-symbols-outlined">stethoscope</i> Find a Doctor
            </a>
            <a 
              className="btn btn-primary btn-lg bookBtn" 
              href="https://www.sankaranethralaya.org/findadoctor"
            >
              <i className="material-symbols-outlined">calendar_month</i> Book Appointment
            </a>
          </div>

          
          <div className="nav-menu mb-view-m" >
            <nav className="navbar navbar-expand-lg">
              <div className="container d-block">
                <button 
                  className="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarScroll" 
                  aria-controls="navbarScroll" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation"
                  
                >
                  <div className="hamburger-btn offcanvas-open-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                  <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll m-auto">
                    
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="https://sankaranethralaya.org/sn-demo/index.html">Home</a>
                    </li>
                    
                    <li className="nav-item dropdown">
                      <a className="nav-link menu-item expand-btn open" href="https://sankaranethralaya.org/sn-demo/#">
                        About Us <i className="material-symbols-outlined">arrow_drop_down</i>
                      </a>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/about-policy.html">Quality Policy</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/board-members.html">MRF Board Members</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/awards.html">Achievements</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/testimonials.html">Testimonials</a>
                      </div>
                    </li>
                    
                    <li className="nav-item dropdown">
                      <a className="nav-link menu-item expand-btn open" href="https://sankaranethralaya.org/sn-demo/#">
                        Patients <i className="material-symbols-outlined">arrow_drop_down</i>
                      </a>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/eye-examination-diseases.html">Patient Care</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/preliminary-eye-examination.html">Preliminary Examination</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/optical-services.html">Optical Services</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/eye-surgery-post-operative-instructions.html">Post Operative Care</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/eye-examination-service-international-patients.html">International Patient Service</a>
                        <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/doorstep-eye-care-services.html">Door Step Eye Care</a>
                        
                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Special Procedures <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/therapeutic-facilities.html" className="menu-item">Therapeutics</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/investigative-facilities-equipments.html" className="menu-item">Investigative</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Allied services <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/anaesthesiology.html" className="menu-item">Anaesthesiology</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Specialities <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/cataract.html" className="menu-item">Cataract</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/cornea.html" className="menu-item">Cornea</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/refractive-surgery-lasik.html" className="menu-item">Refractive Surgery</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/glaucoma.html" className="menu-item">Glaucoma</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/neuro-ophthalmology.html" className="menu-item">Neuro Ophthalmology</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/ocular-oncology.html" className="menu-item">Ocular Oncology</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/orbital-oculoplasty.html" className="menu-item">Oculoplasty & Orbit</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/pediatric-ophthalmology.html" className="menu-item">Pediatric Ophthalmology</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/uveitis.html" className="menu-item">Uvea</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/vitreo-retinal.html" className="menu-item">Vitreoretina</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Community Services <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/jcoc.html" className="menu-item">JCOC</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/teleophthalmology.html" className="menu-item">Teleophthalmology</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/mesu.html" className="menu-item">MESU</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/https://eso.sankaranethralaya.org/outreach-screening.html" className="menu-item">School screening Camp</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Specialty Clinics <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/computer-vision-syndrome-clinic.html" className="menu-item">Computer Vision Syndrome Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/contact-lens-clinic.html" className="menu-item">Contact Lens Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/low-vision-care-clinic.html" className="menu-item">Low Vision Care Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/special-children-clinic.html" className="menu-item">Special Children Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/ocular-prosthetic-clinic.html" className="menu-item">Ocular Prosthetic Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/orthoptics-clinic.html" className="menu-item">Orthoptics Clinic</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/occupational-vision-aid-and-rehabilitation.html" className="menu-item">Occupational Vision Aid and Rehabilitation</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Departments <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/contact-lens-department.html" className="menu-item">Contact Lens Department</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/instruments-maintenance-department.html" className="menu-item">Instruments Maintenance Department</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/microbiology.html" className="menu-item">Microbiology</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/optical-dispensing-department.html" className="menu-item">Optical Dispensing Department</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/ocular-pathology-department.html" className="menu-item">Ocular Pathology Department</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/patient-relations.html" className="menu-item">Patient Relations</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/sn-teleophthalmology.html" className="menu-item">Teleophthalmology</a></li>
                          </ul>
                        </div>

                        <div className="dropdown dropdown-right">
                          <a className="dropdown-item menu-item expand-btn" href="https://sankaranethralaya.org/sn-demo/#">
                            Education <i className="material-symbols-outlined">arrow_drop_down</i>
                          </a>
                          <ul className="dropdown-menu menu-right sample">
                            <li><a href="https://sankaranethralaya.org/sn-demo/education-events.html" className="menu-item">Education Events</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/certificate-courses.html" className="menu-item">Certificate Courses</a></li>
                            <li><a href="https://sankaranethralaya.org/sn-demo/campus-interview.html" className="menu-item">Campus Interview</a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    
                    
                    <li className="nav-item">
                      <a className="nav-link" href="https://sankaranethralaya.org/sn-demo/contact-us.html">Contact Us</a>
                    </li>

                    
                    <li className="nav-item">
                      <a className="nav-link" href="https://sankaranethralaya.org/sn-demo/about-overview.html">More Links</a>
                    </li>

                    
                    <li className="nav-item">
                      <a className="nav-link" href="https://sankaranethralaya.org/sn-demo/careers.html">Careers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>

      
        
      
      {/* Menu for desktop */}
      <section className="nav-menu">
        <nav className="navbar navbar-expand-lg bg-blue-menu">
          <div className="container" >
            <button
              className="navbar-toggler navbar-light"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll" >
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll m-auto" >
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="https://sankaranethralaya.org/sn-demo/index.html">
                    Home
                  </a>
                </li>

                <li className="nav-item dropdown" 
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}>
                  <a className="nav-link menu-item expand-btn open" href="https://sankaranethralaya.org/sn-demo/#">
                    About Us
                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  </a>
                  <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                    <div>
                      <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/about-policy.html">
                        Quality Policy
                      </a>
                    </div>
                    <div>
                      <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/board-members.html">
                        MRF Board Members
                      </a>
                    </div>
                    <div>
                      <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/awards.html">
                        Achievements
                      </a>
                    </div>
                    <div>
                      <a className="dropdown-item" href="https://sankaranethralaya.org/sn-demo/testimonials.html">
                        Testimonials
                      </a>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdownMega dropdown" onMouseEnter={() => setMegaDropdownOpen(true)}
                onMouseLeave={() => setMegaDropdownOpen(false)}>
                  <a className="nav-link menu-item expand-btn open" href="https://sankaranethralaya.org/sn-demo/#">
                    Patients
                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  </a>
                  <div className={`dropdown-menu mega ${megaDropdownOpen ? 'show' : ''}`}>
                    <div className="flex">
                      <div className="col">
                        <ul>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/eye-examination-diseases.html"
                            >
                              Patient Care
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/preliminary-eye-examination.html"
                            >
                              Preliminary Examination
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/optical-services.html"
                            >
                              Optical Services
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/eye-surgery-post-operative-instructions.html"
                            >
                              Post Operative Care
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/eye-examination-service-international-patients.html"
                            >
                              International Patient Service
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/doorstep-eye-care-services.html"
                            >
                              Door Step Eye Care
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/sri-nathella-sampathu-chetty-clinical-laboratory-sankara-nethralaya.html"
                            >
                              SNSC Clinical Laboratory
                            </a>
                          </li>
                          <br />
                          <li>
                            <b>Special Procedures</b>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/therapeutic-facilities.html"
                              className="dropdown-item"
                            >
                              Therapeutics
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/investigative-facilities-equipments.html"
                              className="dropdown-item"
                            >
                              Investigative
                            </a>
                          </li>
                          <br />
                          <li>
                            <b>Allied services</b>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/anaesthesiology.html"
                              className="dropdown-item"
                            >
                              Anaesthesiology
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <li>
                            <b>Specialities</b>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/cataract.html" className="dropdown-item">
                              Cataract
                            </a>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/cornea.html" className="dropdown-item">
                              Cornea
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/refractive-surgery-lasik.html"
                              className="dropdown-item"
                            >
                              Refractive Surgery
                            </a>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/glaucoma.html" className="dropdown-item">
                              Glaucoma
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/neuro-ophthalmology.html"
                              className="dropdown-item"
                            >
                              Neuro Ophthalmology
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/ocular-oncology.html"
                              className="dropdown-item"
                            >
                              Ocular Oncology
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/orbital-oculoplasty.html"
                              className="dropdown-item"
                            >
                              Oculoplasty & Orbit
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/pediatric-ophthalmology.html"
                              className="dropdown-item"
                            >
                              Pediatric Ophthalmology
                            </a>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/uveitis.html" className="dropdown-item">
                              Uvea
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/vitreo-retinal.html"
                              className="dropdown-item"
                            >
                              Vitreoretina
                            </a>
                          </li>
                          <br />
                          <li>
                            <b>Community Services</b>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/jcoc.html" className="dropdown-item">
                              JCOC
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/teleophthalmology.html"
                              className="dropdown-item"
                            >
                              Teleophthalmology
                            </a>
                          </li>
                          <li>
                            <a href="https://sankaranethralaya.org/sn-demo/mesu.html" className="dropdown-item">
                              MESU
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://eso.sankaranethralaya.org/outreach-screening.html"
                              className="dropdown-item"
                            >
                              School screening Camp
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <li>
                            <b>Specialty Clinics</b>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/computer-vision-syndrome-clinic.html"
                              className="dropdown-item"
                            >
                              Computer Vision Syndrome Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/contact-lens-clinic.html"
                              className="dropdown-item"
                            >
                              Contact Lens Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/low-vision-care-clinic.html"
                              className="dropdown-item"
                            >
                              Low Vision Care Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/special-children-clinic.html"
                              className="dropdown-item"
                            >
                              Special Children Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/ocular-prosthetic-clinic.html"
                              className="dropdown-item"
                            >
                              Ocular Prosthetic Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/orthoptics-clinic.html"
                              className="dropdown-item"
                            >
                              Orthoptics Clinic
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/occupational-optometry.html"
                              className="dropdown-item"
                            >
                              Occupational Optometry Services
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/vision-enhancement-clinic.html"
                              className="dropdown-item"
                            >
                              Vision Enhancement Clinic
                            </a>
                          </li>
                          <br />
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdownMega dropdown" onMouseEnter={() => setAcademicDropdownOpen(true)}
                  onMouseLeave={() => setAcademicDropdownOpen(false)}>
                  <a className="nav-link menu-item expand-btn open" href="https://sankaranethralaya.org/sn-demo/#">
                    Academics
                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  </a>
                  <div className={`dropdown-menu mega ${academicDropdownOpen ? 'show' : ''}`}>
                    <div className="flex">
                      <div className="col">
                        <ul>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/education-training.html"
                            >
                              Education and Training at SN
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/ophthalmic-post-graduate-training-center-chennai.html"
                            >
                              CU Shah Ophthalmic PG Training Center
                            </a>
                          </li>
                          <br />
                          <li>
                            <b>Fellowships</b>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/short-term-ocular-surface-fellowship.html"
                            >
                              Short-Term Ocular Surface Fellowship
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/paediatric-ophthalmology-fellowship.html"
                            >
                              Paediatric Ophthalmology Training
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/comprehensive-ophthalmology-fellowship.html"
                            >
                              Comprehensive Ophthalmology
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/cornea-anterior-segment-fellowship.html"
                            >
                              Cornea
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/uvea-fellowship.html"
                            >
                              Uvea
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/glaucoma-fellowship.html"
                            >
                              Glaucoma
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/oculoplasty-orbit-fellowship.html"
                            >
                              Oculoplasty
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/vitreoretinal-fellowship.html"
                            >
                              Vitreoretina and Medical Retina
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/neuro-ophthalmology-fellowship.html"
                            >
                              Neuro Ophthalmology
                            </a>
                          </li>
                          <br />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/ms-ophthalmology.html"
                            >
                              MS Ophthalmology
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://sankaranethralaya.org/sn-demo/dnb-ophthalmology.html"
                            >
                              DNB Ophthalmology
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <li>
                            <b>Doctoral Degree Programmes</b>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/biochemistry-phd-programme.html"
                              className="dropdown-item"
                            >
                              PhD Biochemistry
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/genetics-phd-programme.html"
                              className="dropdown-item"
                            >
                              PhD Molecular Biology and Genetics
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/pathalogy-phd-programme.html"
                              className="dropdown-item"
                            >
                              PhD Pathology
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/microbiology-phd-programme.html"
                              className="dropdown-item"
                            >
                              PhD Microbiology
                            </a>
                          </li>
                          <br />
                          <li>
                            <b>VIBS</b>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/hands-on-summer-training-programme.html"
                              className="dropdown-item"
                            >
                              Hands on Summer Training
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/short-term-research-project-programme.html"
                              className="dropdown-item"
                            >
                              Short Term Research Project
                            </a>
                          </li>
                          <br />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://eso.sankaranethralaya.org/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Elite School of Optometry
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://snesokolkata.org/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Elite School of Optometry Kolkata
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="http://www.thesnacademy.ac.in/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Sankara Nethralaya Academy
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://sankaranethralaya.org/sn-demo/library-ophthalmology.html"
                              className="dropdown-item"
                            >
                              Library
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.sankaranethralaya.org/"
                  >
                    Research
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="https://sankaranethralaya.org/sn-demo/donations.html">
                    Donate Now
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.sankaranethralaya.org/snapps/feedback-form.aspx"
                  >
                    Feedback
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://sankaranethralaya.org/sn-demo/contact-us.html">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Header;
