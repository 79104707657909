import { useState } from "react";
import "./App.css";
import DoctorsCard from "./components/DoctorsCard";
//import Users from './components/Users';
import DoctorsData from "./components/DoctorsData";
import FilterCard from "./components/FilterCard";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";

function App() {
  // const [selectedFilter, setSelectedFilter] = useState([])
  const [item, setItem] = useState(DoctorsData);
  const [deptItem, setDeptItem] = useState(DoctorsData);
  const filterItems = [...new Set(DoctorsData.map((val) => val.department))];
  const locationFilter = [...new Set(DoctorsData.map((val) => val.location))];

  const [filteredByLocation, setFilteredByLocation] = useState(DoctorsData);
  const [finalFilteredData, setFinalFilteredData] = useState(DoctorsData);

  const doctorLocation = (location) => {
    const filteredData = DoctorsData.filter(
      (doctor) => doctor.location === location
    );
    setFilteredByLocation(filteredData);
  };

  const filterDepartment = (department) => {
    const filteredData = filteredByLocation.filter(
      (doctor) => doctor.department === department
    );
    setFinalFilteredData(filteredData);
  };

  // Optional: Handle "All" selection to reset filters
  const resetFilters = () => {
    setFilteredByLocation(DoctorsData);
    setFinalFilteredData(DoctorsData);
  };
  
return (
    <div className="App">
      <div className="App-header">
      <Header/>
      <h3 className="findadoc">Find A Doctor</h3>
        <div className="mainComponent">
          <div>
            <FilterCard
              filterItems={filterItems}
              filterDepartment={filterDepartment}
              locationFilter={locationFilter}
              doctorLocation={doctorLocation}
              setItem={setItem}
              setDeptItem={setDeptItem}
              resetFilters={resetFilters}
              finalFilteredData={finalFilteredData}
            />
          </div>
          <div>
            <DoctorsCard item={item} deptItem={deptItem} finalFilteredData={finalFilteredData} />
          </div>
        </div>
        <BackToTop/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
