const DoctorsData = [
    {
        id: "63",
        name: "Dr. Ronnie Jacob George",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-ronnie-jacob-george.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-ronnie-jacob-george.html"
    },
    {
        id: "64",
        name: "Dr. Bhaskaran M",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai",
        
    },
    {
        id: "16",
        name: "Dr. Lingam Vijaya",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-vijaya-l.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-lingam-vijaya.html"
    },
    {
        id: "26",
        name: "Dr. Shantha B",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-shantha-b.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-shantha-b.html"
    },
    {
        id: "24",
        name: "Dr. Sripriya Krishnamoorthy",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sripriya-krishnamoorthy.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sripriya-krishnamoorthy.html"
    },
    {
        id: "65",
        name: "Dr. Parivadhini A",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-parivadhini-a.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-parivadhini-a.html"
    },
    {
        id: "22",
        name: "Dr. Rathini lilian david",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-rathini-lilian-david.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-rathini-lilian-david.html"
    },
    {
        id: "66",
        name: "Dr. Mona Khurana",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-mona-khurana.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-mona-khurana.html"
    },
    {
        id: "34",
        name: "Dr. Trupti Sudhir Patil",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-trupti-sudhir-patil.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-trupti-sudhir-patil.html"
    },
    {
        id: "25",
        name: "Dr. Sujatha V K",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sujatha-v-k.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sujatha-v-k.html"
    },
    
    {
        id: "67",
        name: "Dr. Chandra Kanth",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-chandra-kanth.jpg",
        location: "Chetpet" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-chandra-kanth.html"
    },
    
    {
        id: "1",
        name: "Dr. Abha Mukund Mashruwala",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-abha-mukund-mashruwala.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-abha-mukund-mashruwala.html"
    },
    {
        id: "2",
        name: "Dr. Ambika S",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-ambika-s.jpg",
        location: "Chennai",
        
    },
    {
        id: "76",
        name: "Dr. Sumita Agarkar",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sumita-agarkar.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sumita-agarkar.html"
    },
    {
        id: "77",
        name: "Dr. Kavitha Kalaivani",  
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-kavitha-kalanani-n.jpg",
        location: "Chennai",
        
    },
    {
        id: "31",
        name: "Dr. Surendran T S",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-t-s-surendran.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-surendran-t-s.html"
    },
    {
        id: "3",
        name: "Dr. V Akila Ramkumar",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-v-akila-ramkumar.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-v-akila-ramkumar.html"
    },
    {
        id: "4",
        name: "Dr. Akshay Badakere",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-akshay-badakere.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-akshay-badakere.html"
    },
    {
        id: "86",
        name: "Dr. Pramod Bhende",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-pramod-s-bhende.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "17",
        name: "Dr. Muna Bhende",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-muna-bhende.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-muna-bhende.html"
    },
    {
        id: "15",
        name: "Dr. Lingam Gopal",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-lingam-gopal.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-lingam-gopal.html"
    },
    {
        id: "90",
        name: "Dr. Girish Shiva Rao",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-girish-shiva-rao.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-girish-shiva-rao.html"
    },
    {
        id: "7",
        name: "Dr. Chetan Rao",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-chetan-rao.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-chetan-rao.html"
    },
    {
        id: "20",
        name: "Dr. Pradeep Susvar",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-pradeep-susvar.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-pradeep-susvar.html"
    },
    {
        id: "87",
        name: "Dr. Suganeswari G",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-suganeswari-g.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-suganeswari-g.html"
    },
    {
        id: "88",
        name: "Dr. Vinata Rajendran",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-vinata-rajendran.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "89",
        name: "Dr. Rajiv Raman",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-rajiv-raman.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "13",
        name: "Dr. V Jaya Prakash",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-v-jaya-prakash.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-v-jaya-prakash.html"
    },
    {
        id: "8",
        name: "Dr. Dhanashree Ratra",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-dhanashree-ratra.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-dhanashree-ratra.html"
    },
    {
        id: "6",
        name: "Dr. Charanya C",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-charanya-c.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-charanya-c.html"
    },
    {
        id: "12",
        name: "Dr. Janani Sreenivasan",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-janani-sreenivasan.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-janani-sreenivasan.html"
    },
    {
        id: "5",
        name: "Dr. Arkaprava Pradhan",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-arkaprava-pradhan.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-arkaprava-pradhan.html"
    },
    {
        id: "91",
        name: "Dr. Preethi P",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-preethi.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-p-preethi.html"
    },
    {
        id: "23",
        name: "Dr. Raaja Ganesh M",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-raaja-ganesh-m.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-raaja-ganesh-m.html"
    },
    
    {
        id: "92",
        name: "Dr. Zubin D’ Souza",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-zubim-d-souza.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "93",
        name: "Dr. Eesh Nigam",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-eesh-nigam.jpg",
        location: "Kolkata" ,
        
    },
    
    {
        id: "48",
        name: "Dr. Debmalya Das",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-debmalya-das.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-debmalya-das.html"
    },
      
    {
        id: "49",
        name: "Dr. Kalpita Das",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-kalpita-das.html"
    },
    {
        id: "94",
        name: "Dr. Maitreyi Chowdhury",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-maitreyi-chowdhury.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "95",
        name: "Dr. Rupak Roy",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "96",
        name: "Dr. Surabhi Chattree",
        department: "Vitreo Retina",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-surbhi-chetree.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "11",
        name: "Dr. Jyotirmay Biswas",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-jyotirmay-biswas-v2.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-jyotirmay-biswas.html"
    },
    {
        id: "32",
        name: "Dr. Sudha k Ganesh",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sudha-k-ganesh.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sudha-k-ganesh.html"
    },
    {
        id: "80",
        name: "Dr. Amala Elizabeth George",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-amala-elizabeth-george.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "81",
        name: "Dr. Sudharshan S",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        
    },
    
    {
        id: "50",
        name: "Dr. Bhuvaneshwaran.V",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-bhuvaneshwaran-vedapuri-eswaran.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-bhuvaneshwaran-v.html"
    },
   
    {
        id: "9",
        name: "Dr. Divya Shetty",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-divya-shetty.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-divya-shetty.html"
    },
    {
        id: "68",
        name: "Dr. Smita Panda",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-smita-panda.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "27",
        name: "Dr. Swathi Vallabh",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-swathi-vallabh.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-swathi-vallabh.html"
    },
    {
        id: "72",
        name: "Prof. Dr. Nirmala Subramanian",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "73",
        name: "Dr. Bipasha Mukherjee",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "45",
        name: "Dr. Md. Shahid Alam",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-md-shahid-alam.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-md-shahid-alam.html"
    },
    {
        id: "14",
        name: "Dr. Kirthi Koka",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-kirthi-koka.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-kirthi-koka.html"
    },
    {
        id: "74",
        name: "Dr. Nisar Sonam Poonam",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-n-s-poonam.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "75",
        name: "Dr. Varsha Backiavathy",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-varsha-backiavathy-t.html"
    },
   
   
    
    {
        id: "10",
        name: "Dr. Hennaav Kaur Dhillon",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-hennaav-kaur-dhillon.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-hennaav-kaur-dhillon.html"
    },
    {
        id: "78",
        name: "Dr. Sujata Guha",  
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Kolkata",
        
    },
  
    
   
    {
        id: "51",
        name: "Dr. Rama Rajagopal",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-rama-rajagopal.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "21",
        name: "Dr. Radhika N",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-radhika-n.jpg",
        location: "Chennai",
        prolink :"https://www.sankaranethralaya.org/doctors/dr-radhika.html"
    },
    {
        id: "52",
        name: "Dr. Prema Padmanabhan",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-prema-padmanabhan.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-prema-padmanabhan.html"
    },
    {
        id: "53",
        name: "Dr. Sudhir R R",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sudhir-rr.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-r-r-sudhir.html"
    },
    {
        id: "54",
        name: "Dr. Niveditha Narayanan",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-niveditha-narayanan.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "55",
        name: "Dr. Shweta S Agarwal",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-shweta-s-agarwal.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "56",
        name: "Dr. Meena Lakshmipathy",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "35",
        name: "Dr. Varsha Bhambhani",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-varsha-bhambhani.html"
    },
  
    {
        id: "58",
        name: "Dr. Srivalli Kaza",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sri-valli-kaza.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-srivalli-kaza.html"
    },
    {
        id: "59",
        name: "Dr. Mugundhan R",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-muguthan-r.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-mugundhan-rajarajan.html"
    },
    {
        id: "60",
        name: "Dr. Mona Bhargava",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-mona-bhargava.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "61",
        name: "Dr. Aditi Johri",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-aditi-johri.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "62",
        name: "Dr. Mihir A Lagvankar",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-mihir-a-lagvankar.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-mihir-a-lagvankar.html"
    },
    {
        id: "40",
        name: "Dr. Raj Shekhar Paul",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-raj-sekhar-paul.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "39",
        name: "Dr. Sunita Pandey",
        department: "Cornea",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sunita-pandey.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sunita-pandey.html"
    },
    {
        id: "41",
        name: "Dr. Mandabi Sengupta",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-mandabi-sengupta.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-mandabi-sengupta.html"
    },
    {
        id: "46",
        name: "Dr Manideepa Banerjee",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-manideepa-banerjee.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-manideepa-banerjee.html"
    },
    
    {
        id: "18",
        name: "Dr. Parthapratim Dutta Majumder",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-parthapratim-dutta-majumder.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-parthapratim-dutta-majumder.html"
    },
    {
        id: "19",
        name: "Dr. Preeta Nair A K",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-preeta-nair-a-k.html"
    },
    {
        id: "44",
        name: "Dr. Debi Kundu",
        department: "Oculoplasty",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-debi-kundu.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-debi-kundu.html"
    },
 
    // {
    //     id: "28",
    //     name: "Dr. Sri Valli Kazha",
    //     department: "Comprehensive Ophthalmology",
    //     img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sri-valli-kazha.jpg",
    //     location: "Chennai" ,
    //     prolink :"https://www.sankaranethralaya.org/doctors/dr-srivalli-kaza.html"
    // },
    {
        id: "97",
        name: "Dr. C K Parameswari",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-parameswari-c-k.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-c-k-parameswari.html"
    },
    {
        id: "30",
        name: "Dr. Shikha Bassi",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-shikha-rajesh-bassi.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-shikha-rajesh-bassi.html"
    },
    {
        id: "29",
        name: "Dr. Smita Praveen",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-smita-praveen.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-smita-praveen.html"
    },
    
    
   
    {
        id: "33",
        name: "Dr. Suchitra Pradeep",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-suchitra-pradeep.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-suchitra-pradeep.html"
    },
    {
        id: "82",
        name: "Dr. Arshee S Ahmed",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-arshee-s-ahmed.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "83",
        name: "Dr. Sharanya Sarah Abraham",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-sharanya-sarah-abraham.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-sharanya-sarah-abraham.html"
    },
    {
        id: "84",
        name: "Dr. Vinita Girish Rao",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-vinita-girish-rao.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "85",
        name: "Dr. Amitabh Kumar",
        department: "Uveitis",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-amitabh-kumar.jpg",
        location: "Kolkata" ,
        
    },
    {
        id: "38",
        name: "Dr. Shankar D N",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dummy.jpg",
        location: "Chetpet" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-shankar-d-n.html"
    },
    
    {
        id: "43",
        name: "Dr. Suchetana Mukherjee",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-suchetana-mukherjee.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-suchetana-mukherjee.html"
    },
    
    {
        id: "47",
        name: "Dr. Stuti Somani Agarwal",
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-stuti-somani-agarwal.jpg",
        location: "Kolkata" ,
        
    },
    
    {
        id: "79",
        name: "Dr. Anuradha Sen",  
        department: "Paediatric",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-anuradha-sen.jpg",
        location: "Kolkata",
        
    },
  
    {
        id: "36",
        name: "Dr. Vineet Ratra",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-vineet-ratra.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-vineet-ratra.html"
    },
    {
        id: "99",
        name: "Dr. Veena Baskaran",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-veena-baskaran.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-veena-baskaran.html"
    },
    {
        id: "98",
        name: "Dr. Shema Rafeek",
        department: "Comprehensive Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-shema-rafeek.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-shema-rafeek.html"
    },
    {
        id: "70",
        name: "Dr. Durga Priyadarshini S",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-durga-priyadarshini-s.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "37",
        name: "Dr. Vidhya Dharani D M",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-vidhya-dharani-d-m.jpg",
        location: "Chennai" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-vidhya-dharani-dm.html"
    },
    {
        id: "71",
        name: "Dr. Padmalakshmi K",
        department: "Neuro Ophthalmology",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-padmalakshmi-k.jpg",
        location: "Chennai" ,
        
    },
    {
        id: "42",
        name: "Dr. Wadke Vidya Arunkumar",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-wadke-vidya-arunkumar.jpg",
        location: "Kolkata" ,
        prolink :"https://www.sankaranethralaya.org/doctors/dr-wadke-vidya-arunkumar.html"
    },
    {
        id: "69",
        name: "Dr. Chandana Dutta",
        department: "Glaucoma",
        img:"https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-chandana-dutta.jpg",
        location: "Kolkata" ,
        
    },
    
    
 
]

export default DoctorsData;