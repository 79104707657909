import React, { useState } from "react";
import "./DoctorsCard.css";

const DoctorsCard = ({ item, deptItem, finalFilteredData }) => {
  const [query, setQuery] = useState("");

  return (
    <>
      <div className="input-container">
        <input
          type="text"
          placeholder="Search by Doctor Name..."
          className="searchInput"
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="card-container">
        <div className="main-container">
          {finalFilteredData
            .filter((doc) => doc.name.toLowerCase().includes(query))
            .map((doctor, index) => {
              return (
                <a
                  href={doctor.prolink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="doctor-main"
                  key={index}
                >
                  <div className="doctor-main">
                    <div className="doctor-box">
                      <div className="doc-img">
                        <img src={doctor.img} alt="docImg" />
                      </div>
                      <div className="content">
                        <h5>{doctor.name}</h5>
                        {/* <h5 className="secName">{doctor.secName}</h5> */}
                        <div className="doc-flex">
                          {/* <div class="desig">Consultant </div> */}
                          <div className="dept">
                            <i className="material-symbols-outlined">
                              stethoscope
                            </i>
                            {doctor.department}
                          </div>
                          <div className="dept">
                            <i className="material-symbols-outlined">
                              location_on
                            </i>
                            {doctor.location}
                          </div>
                        </div>

                        {/* <div className="btn-container">
                        <div className="btn btn-primary btn-sm">
                          <i className="material-symbols-outlined">
                            calendar_month
                          </i>
                          Book Appointment
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
        </div>

        {/* <div className="main-container">
          {deptItem.filter((doc) => doc.location.toLowerCase().includes(query))
            .map((data) => {
              
              return (
                <div className="doctor-main" key={data.id}>
                  <div className="doctor-box">
                    <div className="doc-img">
                      <img src={data.img} alt="docImg" />
                    </div>
                    <div className="content">
                      <h5>{data.name}</h5>
                      
                      <div className="doc-flex">
                        
                        <div className="dept">
                          <i className="material-symbols-outlined">
                            stethoscope
                          </i>
                          {data.department}
                        </div>
                        <div className="dept">
                          <i className="material-symbols-outlined">
                            location_on
                          </i>
                          {data.location}
                        </div>
                      </div>

                      <div className="btn-container">
                        <div className="btn btn-primary btn-sm">
                          <i className="material-symbols-outlined">
                            calendar_month
                          </i>
                          Book Appointment
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div> */}
      </div>
    </>

    // <div className="doctor-box">
    //   <div className="doc-img">
    //     <img src="https://sankaranethralaya.org/fad/admin/assets/doctor-img/dr-radhika-n.jpg" alt="docImg"/>
    //   </div>
    //   <div className="content">
    //     <h5>
    //       Dr. Radhika N
    //     </h5>
    //     <div className="doc-flex">
    //       {/* <div class="desig">Consultant </div> */}
    //       <div className="dept">
    //         <i className="material-symbols-outlined">stethoscope</i> Cornea
    //       </div>
    //     </div>
    //     <div className="btn btn-primary btn-sm" >
    //       <i className="material-symbols-outlined">calendar_month</i> Book
    //       Appointment
    //     </div>
    //   </div>
    // </div>
  );
};

export default DoctorsCard;
