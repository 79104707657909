import React, { useState } from "react";
import "./FilterCard.css";
// import DoctorsData from "./DoctorsData";

const FilterCard = ({
  filterItems,
  locationFilter,
  doctorLocation,
  filterDepartment,
  resetFilters,
  finalFilteredData,
  // setItem,
  // setDeptItem,
}) => {
  // State to keep track of the selected department
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Sample mapping of locations to available departments
  const locationToDepartments = {
    Chetpet: ["Comprehensive Ophthalmology"],
    Chennai: [
      "Cornea",
      "Comprehensive Ophthalmology",
      "Glaucoma",
      "Neuro Ophthalmology",
      "Oculoplasty",
      "Paediatric",
      "Vitreo Retina",
      "Uveitis",
    ],
    Kolkata: [
      "Cornea",
      "Comprehensive Ophthalmology",
      "Glaucoma",
      "Oculoplasty",
      "Paediatric",
      "Vitreo Retina",
      "Uveitis",
    ]
    // Add other location-department mappings here
  };

  //  Function to handle location selection
  const handleLocation = (val) => {
    setSelectedLocation(val);
    setSelectedDepartment(null); // Reset department when location changes
    doctorLocation(val);
    // console.log(selectedLocation);
  };

  //  Function to handle department selection
  const handleDepartmentClick = (val) => {
    setSelectedDepartment(val);
    filterDepartment(val);
  };

  // Function to handle "All" selection
  const handleAllClick = () => {
    setSelectedLocation(null);
    setSelectedDepartment(null); // Reset the selected department
    resetFilters(); // Call the reset function
  };

  // Filter departments based on the selected location
  const filteredDepartments = selectedLocation ? locationToDepartments[selectedLocation] || []
    : filterItems;

  return (
    <div className="leftMain">
      <div className="leftPanel">
        <div className="filterTitle">
          <h5>Select Location</h5>
        </div>

        <div>
          <p className="filterBtn">
            <input
              type="checkbox"
              className="checkBox"
              checked={selectedLocation === null && selectedDepartment === null}
              onChange={handleAllClick }
            />
            <span onClick={handleAllClick}>All</span>
          </p>
        </div>

        <div className="location-main">
          <div className="input-main">
            {locationFilter.map((val, id) => (
              <div key={`location-${id}`}>
                <p className="filterBtn" onClick={() => handleLocation(val)}>
                  <input
                    type="checkbox"
                    className="checkBox"
                    checked={selectedLocation === val}
                    onChange={() => handleLocation(val)}
                  />
                  {val}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="filterTitle">
          <h5>Select Department</h5>
        </div>
        <div>
          {/* <div>
            <p className="filterBtn" onClick={resetFilters}>
              <input
                type="checkbox"
                className="checkBox"
                checked={selectedDepartment === null}
                onChange={handleAllClick}
              />
              All
            </p>
          </div> */}
          <div className="input-main">
            {filteredDepartments.map((val, id) => (
              <div key={`department-${id}`}>
                <p
                  className="filterBtn"
                  onClick={() => handleDepartmentClick(val)}
                >
                  <input
                    type="checkbox"
                    className="checkBox"
                    checked={selectedDepartment === val}
                    onChange={() => handleDepartmentClick(val)}
                  />
                  {val}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Render the filtered doctors */}
      {/* <div className="doctor-list">
        {finalFilteredData.map((doctor, index) => (
          <div key={index} className="doctor-item">
            <p>{doctor.name}</p>
            <p>{doctor.location}</p>
            <p>{doctor.department}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default FilterCard;
