import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      {/* <section className="fixed-btn mobhide">
  <div className="container text-center">
    <div className="flex-fixed-desk m-auto">


          <div className="flex-box">
              <a href="https://sankaranethralaya.org/fad/find-a-doctor.html">
                  <i className="material-symbols-outlined">stethoscope</i>
                  <p>
                      Find a Doctor
                  </p>
              </a>
          </div>

          <div className="flex-box">

              <a href="#">
                  <i className="material-symbols-outlined">calendar_month</i>
                  <p>
                      Appointments
                  </p>
              </a>
          </div>
      </div>
  </div>
</section>
<section className="fixed-btn deskhide ">
  <div className="container text-center ">
      <div className="flex-fixed">

          <div className="col">
              <a href="https://sankaranethralaya.org/fad/find-a-doctor.html">
                  <i className="material-symbols-outlined">stethoscope</i>
                  <p>
                      Our Doctors
                  </p>
              </a>
          </div>

          <div className="col">
              <a href="#">
                  <i className="material-symbols-outlined">calendar_month</i>
                  <p>
                      Appointments
                  </p>
              </a>
          </div>
      </div>
  </div>
</section> */}

      {/* <!-- footer start>-->
<!-- scroll top start--> */}
      <button
        className="btn btn-sm btn-primary rounded-circle position-fixed bottom-0 end-0 translate-middle d-none"
        onclick="scrollToTop()"
        id="back-to-up"
      >
        <span className="material-icons">expand_less</span>
      </button>
      {/* <!-- scroll top end--> */}
      <section className="footer-sec pdt pdb">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <p className="ft-title">Address</p>

              <div className="info-ft">
                <p>
                  <strong>
                    Sankara Nethralaya
                    <br /> (Padma Bhushan Dr. S. S. Badrinath Campus)
                  </strong>
                  <br />
                  No. 41 (old 18), College Road,
                  <br /> Chennai 600 006, Tamil Nadu, India.
                </p>

                <p>
                  mrf@snmail.org
                  <br />
                </p>
                <p>
                  +91 44 4227 1500
                  <br />
                  +91 44 2827 1616
                </p>
              </div>
            </div>
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <p className="ft-title">Quick Links</p>
              <ul className="p-0">
                <li>
                  <a href="jobs-careers.html">Career</a>
                </li>
                <li>
                  <a href="c-u-shah-eye-bank.html">Eye Bank</a>
                </li>
                <li>
                  <a href="e-learning-ophthalmology-optometry.html">
                    e-Learning
                  </a>
                </li>

                <li>
                  <a href="magazines-publications.html">Publications</a>
                </li>

                <li>
                  <a
                    href="assets/pdf/sankara-nethralaya-vision-mission-statement.pdf"
                    target="_blank"
                  >
                    Vision & Mission
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <ul className="p-0">
                {/* <!--<li>
                      <a href="#">
                      BMW Report
                      </a>
                  </li>--> */}
                <li>
                  <a href="activity-report.html">Activity Report 2023-2024</a>
                </li>

                <li>
                  <a href="internal-complaints-committee.html">
                    Internal Complaints Committee
                  </a>
                </li>
                <li>
                  <a href="nabh-accreditation-certificate.html">NABH</a>
                </li>
                <li>
                  <a href="sn-bmw-report.html">BMW Report</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3" data-aos="fade-up">
              <p className="ft-title">Our Websites</p>
              <ul className="p-0">
                <li>
                  <a
                    href="https://www.supportsankaranethralaya.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support Sankara Nethralaya
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sankaranethralayausa.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sankara Nethralaya USA
                  </a>
                </li>
                <li>
                  <a
                    href="https://omlog.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Omlog
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.thesnacademy.ac.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Sankara Nethralaya Academy
                  </a>
                </li>

                <li>
                  <a
                    href="http://www.ekalavya.org/elearn/EkalavyaUser/Index.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ekalavya
                  </a>
                </li>

                <li>
                  <a
                    href="https://eso.sankaranethralaya.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Elite School of Optometry
                  </a>
                </li>
                <li>
                  <a
                    href="https://snesokolkata.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sankara Nethralaya ESO Kolkata
                  </a>
                </li>
              </ul>
              <p className="ft-title">Our Partner in Ophthalmic Research</p>
              <ul className="p-0">
                <li>
                  <a
                    href="https://visionresearchfoundation.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vision Research Foundation
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="footer-bottom " data-aos="fade-up">
            <div className="row">
              <div class="col-md-8">
                <p>
                  Sankara Nethralaya | Copyright © 2024 | All Rights Reserved |
                  <a
                    href="https://www.dreameffectsmedia.com/web-design-chennai"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Website Design
                  </a>
                  by
                  <a href="https://www.dreameffectsmedia.com/" target="_blank" rel="noopener noreferrer">
                    Dream Effects
                  </a>
                </p>
              </div>
              <div className="col-md-4 pbm-50">
                <div className="social-icons float">
                  <a
                    href="https://www.facebook.com/Sankaranethralaya.Officialpage/"
                    target="_blank"
                    className=""
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.sankaranethralaya.org/assets/img/facebook-Icon.png"
                      className="sicons"
                      alt="facebookIcon"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/sankaranethralaya.officialpage/"
                    target="_blank"
                    className=""
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.sankaranethralaya.org/assets/img/instagram-icon.png"
                      className="sicons"
                      alt="instIcon"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/sankaranethralaya"
                    target="_blank"
                    className=""
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.sankaranethralaya.org/assets/img/youtube-icon.png"
                      className="sicons"
                      alt="youtubeIcon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
